import DOMPurify from "dompurify";

const sanitizeInput = (input: string) => {
  const sanitizedInput = DOMPurify.sanitize(input);
  return sanitizedInput;
};

export const sanitizeData = (data: any) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      data[key as keyof Object] = sanitizeInput(data[key]);
    });
  }
  return data;
};
