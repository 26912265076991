import { ButtonEnum, ButtonTypeEnum } from "../../beans";
import { Wrap } from "./styles";
import { MouseEventHandler } from "react";
import { loadingLogo } from "../../constants/icons";
import Icon from "../Icon";

type Props = {
  id?: string;
  type?: ButtonTypeEnum;
  style: ButtonEnum;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
};
const Button = (props: Props) => {
  return (
    <Wrap
      type={props.type ? props.type : "submit"}
      id={props.id}
      disabled={props.disabled}
      onClick={props.onClick}
      className={
        props.loading
          ? `loading ${props.style} ${props.className}`
          : `${props.style} ${props.className}`
      }
    >
      {props.loading && (
        <div className="loading-spinner">
          <Icon icon={loadingLogo} />
        </div>
      )}
      {props.children}
    </Wrap>
  );
};

export default Button;
