import { MouseEventHandler, RefObject, useEffect, useRef } from "react";
import { ButtonEnum, ButtonTypeEnum } from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import Icon from "../Icon";
import { requestQuote } from "../../constants/icons";
import { useTranslation } from "react-i18next";

type Props = {
  size?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
const RequestProductCard = (props: Props) => {
  const { t } = useTranslation();
  const hubspotTrigger: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-eu1.hs-scripts.com/26490882.js";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  }, []);
  return (
    <Wrap onClick={props.onClick} key={"custom"} className={props.size}>
      <div className="request-box">
        <Icon icon={requestQuote} />
        <div
          className="description body-sm"
          dangerouslySetInnerHTML={{ __html: t("requestProduct.description") }}
        />

        <div className="action-container" ref={hubspotTrigger}>
          <Button
            type={ButtonTypeEnum.button}
            style={ButtonEnum.primary}
            className="hs-cta-trigger-button hs-cta-trigger-button-107720768199"
            onClick={() => {}}
          >
            {t("requestProduct.button")}
          </Button>
        </div>
      </div>
    </Wrap>
  );
};

export default RequestProductCard;
