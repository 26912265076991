import { Route, Routes } from "react-router";
import { routes } from "./constants/routes";
import Design from "./views/Design";
import DesignNew from "./views/DesignNew";
import Home from "./views/Home";
import OrderProduction from "./views/OrderProduction";
import OrderShipping from "./views/OrderShipping";
import Checkout from "./views/Checkout/indes";
import OrderCompleted from "./views/OrderCompleted";
import NotFound from "./views/NotFound";
import "./App.css";
import Production from "./views/Production";
import PasswordReset from "./views/PasswordReset";
import PasswordConfirm from "./views/PasswordConfirm";

import * as FullStory from "@fullstory/browser";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const App = () => {
  const TRACKING_ID = "G-6DVM5DRTT5"; // your Measurement ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: document.location.pathname,
      title: document.title,
    });
  }, []);
  if (!window._fs_initialized) FullStory.init({ orgId: "o-20T5GR-na1" });

  return (
    <Routes>
      <Route element={<Design />} path={routes.design} />
      <Route element={<Production />} path={routes.production} />
      <Route element={<Home />} path={routes.home} />
      <Route element={<DesignNew />} path="/design/edit/:designId" />
      <Route
        element={<OrderProduction />}
        path="/design/:designId/order/:orderId/production/:productionId"
      />
      <Route
        element={<OrderShipping />}
        path="/design/:designId/order/:orderId/production/:productionId/shipping/"
      />
      <Route
        element={<Checkout />}
        path="/design/:designId/order/:orderId/production/:productionId/checkout/"
      />
      <Route element={<OrderCompleted />} path="/order/:orderId/completed/" />

      <Route element={<PasswordReset />} path={routes.passwordReset} />
      <Route
        element={<PasswordConfirm />}
        path="/auth/password/reset/confirm/:uid/:token/"
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
