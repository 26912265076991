import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch } from "../../store";
import {
  hideActionModal,
  resetActionModal,
  selectTab,
  showActionModal,
} from "../../store/UI";
import { update } from "../../store/UserDesign";
import { deleteDesignRequest } from "../../store/Design";
import {
  goToEditDesign,
  goToOrderProduction,
  routes,
} from "../../constants/routes";
import {
  getPendingOrder,
  hasClosedOrder,
  hasPendingOrders,
} from "../../utils/orders";
import { ArtworkDetail, ButtonEnum, TabEnum, UserDesign } from "../../beans";
import ArtworkPreview from "../ArtworkPreview";
import Button from "../Button";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
  design: UserDesign;
};
const DesignPreview = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { design } = props;
  const multiplePrint = design.print_set.length > 1;

  const deleteDesign = (id: number) => {
    dispatch(resetActionModal());
    dispatch(deleteDesignRequest(id));
  };

  const openDeleteModal = (id: number) => {
    dispatch(
      showActionModal({
        title: t("designPreview.deleteModal.title"),
        error: `<div class="title-sm">${t("designPreview.deleteModal.contenTitle")}</div><p class="label-lg">${t("designPreview.deleteModal.contenDescription")}</p>`,
        actions: [
          {
            style: ButtonEnum.secondary,
            onClick: () => dispatch(hideActionModal()),
            label: t("designPreview.deleteModal.keep"),
          },
          {
            style: ButtonEnum.primary,
            onClick: () => deleteDesign(id),
            label: t("designPreview.deleteModal.delete"),
          },
        ],
      })
    );
  };

  const orderDesign = (design: UserDesign) => {
    const pendingOrder = getPendingOrder(design);
    if (pendingOrder) {
      if (pendingOrder.orderId) {
        navigate(
          goToOrderProduction(
            design.id,
            pendingOrder.orderId,
            pendingOrder.productionId
          )
        );
      } else if (pendingOrder.productionId) {
        navigate(
          goToOrderProduction(
            design.id,
            pendingOrder.orderId,
            pendingOrder.productionId
          )
        );
      }
    }
  };

  const editDesign = (design: UserDesign) => {
    dispatch(update(design));
    dispatch(selectTab(TabEnum.summary));
    navigate(goToEditDesign(design.id));
  };

  return (
    <Wrap>
      <div
        className={
          multiplePrint ? `multiple design-preview-card` : `design-preview-card`
        }
      >
        {design.print_set.length ? (
          design.print_set.map((artwork: ArtworkDetail, k: number) => {
            return (
              <div className="artwork" key={k}>
                <ArtworkPreview artwork={artwork} design={design} />
              </div>
            );
          })
        ) : (
          <ArtworkPreview design={design} />
        )}
        <div className="actions">
          {/* EDIT */}
          {!hasClosedOrder(design) && !hasPendingOrders(design) && (
            <Button
              style={ButtonEnum.primary}
              onClick={() => editDesign(design)}
            >
              {t("button.edit")}
            </Button>
          )}

          {/* ORDER */}
          {hasPendingOrders(design) && !hasClosedOrder(design) && (
            <Button
              style={ButtonEnum.primary}
              onClick={() => {
                orderDesign(design);
              }}
            >
              {t("button.order")}
            </Button>
          )}
          {/* DELETE */}
          {!hasClosedOrder(design) && (
            <Button
              style={ButtonEnum.secondary}
              onClick={() => {
                openDeleteModal(design.id);
              }}
            >
              {t("button.delete")}
            </Button>
          )}
          {hasClosedOrder(design) && (
            <Button
              style={ButtonEnum.secondary}
              onClick={() => {
                navigate(routes.production);
              }}
            >
              {t("button.showOrder")}
            </Button>
          )}
        </div>
      </div>
      <div className="design-name">{design.name}</div>
    </Wrap>
  );
};

export default DesignPreview;
