import { useEffect } from "react";
import { getOrderSummaryRequest } from "../../store/Order";
import Header from "../../components/Header";
import { ButtonEnum, StripePaymentType } from "../../beans";
import PaddingManager from "../../components/PaddingManager";
import SubsectionTitle from "../../components/SubsectionTitle";
import { Wrap } from "./styles";
import { stripePublishableKey } from "../../constants/endpoints";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/CheckoutForm";
import Card from "../../components/Card";
import ProductionDesigns from "../../components/ProductionDesigns";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AppDispatch, RootState } from "../../store";
import ProductionSummary from "../../components/ProductionSummary";
import ShippingDetails from "../../components/ShippingDetails";
import { goToOrderProduction, goToOrderShipping } from "../../constants/routes";
import BillingDetails from "../../components/BillingDetails";
import { getUserDesignThunk } from "../../store/UserDesign";
import { getProductionByIdRequest } from "../../store/Production";
import LoadingOptions from "../../components/LoadingOptions";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { designId, orderId, productionId } = useParams();

  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const production = useSelector((state: RootState) => state.production.data);
  const summary = useSelector((state: RootState) => state.order.summary?.costs);

  // HOOKS
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderSummaryRequest(orderId));
    }

    if (!userDesign?.id && designId) dispatch(getUserDesignThunk(designId));
    if (!production?.id && productionId)
      dispatch(
        getProductionByIdRequest({
          designId: designId,
          productionId: productionId,
        })
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const editProductionButton = designId && productionId && (
    <Button
      style={ButtonEnum.link}
      onClick={() =>
        navigate(
          goToOrderProduction(
            Number(designId),
            Number(orderId),
            Number(productionId)
          )
        )
      }
    >
      {t("button.edit")}
    </Button>
  );

  const editShippingButton = designId && productionId && orderId && (
    <Button
      style={ButtonEnum.link}
      onClick={() =>
        navigate(goToOrderShipping(designId, orderId, productionId))
      }
    >
      {t("button.edit")}
    </Button>
  );

  const stripePromise = loadStripe(
    stripePublishableKey ? stripePublishableKey : ""
  );

  const options = {
    payment_method_types: ["card"],
    mode: "payment" as StripePaymentType,
    amount: 666,
    currency: "eur",
  };

  return (
    <>
      <Header />
      <Wrap>
        <PaddingManager>
          <div className="page-containers">
            <div className="page-content">
              <Card title="Production" action={editProductionButton}>
                {userDesign && production ? (
                  <ProductionDesigns
                    production={production}
                    userDesign={userDesign}
                  />
                ) : (
                  <LoadingOptions />
                )}
              </Card>
              <Card
                title={t("checkout.shippingInfo")}
                action={editShippingButton}
              >
                {orderId && (
                  <div className="production-details">
                    <ShippingDetails orderId={orderId} />
                    <BillingDetails orderId={orderId} />
                  </div>
                )}
              </Card>
              <Card title={t("checkout.paymentInfo")} active>
                <SubsectionTitle title={t("checkout.title")}></SubsectionTitle>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>
              </Card>
            </div>
            <ProductionSummary
              summary={summary}
              orderId={orderId}
              designId={designId}
              productionId={productionId}
            />
          </div>
        </PaddingManager>
      </Wrap>
    </>
  );
};

export default Checkout;
