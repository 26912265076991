import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAuthHeader } from "../../api/auth";
import { UserDesign } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface UserDesignState {
  data: UserDesign | null;
  error: null | any;
}

const initialState: UserDesignState = {
  data: null,
  error: null,
};
export const setUserDesignThunk = createAsyncThunk(
  "user_design/set",
  async (itemId: number) => {
    const headers = getAuthHeader();
    const response = await fetch(`${apiUrl}/designs/item/select/`, {
      method: "POST",
      body: JSON.stringify({ item_id: itemId }),
      headers: headers,
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    return await response.json();
  }
);

export const patchUserDesignThunk = createAsyncThunk(
  "user_design/patch",
  async (patchRequest: any) => {
    const headers = getAuthHeader();
    const { designId, payload } = patchRequest;
    const response = await fetch(`${apiUrl}/designs/userdesign/${designId}/`, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers: headers,
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    return await response.json();
  }
);

export const getUserDesignThunk = createAsyncThunk(
  "user_design/get",
  async (designId: string) => {
    const headers = getAuthHeader();
    const response = await fetch(`${apiUrl}/designs/userdesign/${designId}/`, {
      headers: headers,
      credentials: "include",
    });

    return await response.json();
  }
);

export const patchUserDesignPrintThunk = createAsyncThunk(
  "user_design/print/patch",
  async (patchRequest: any) => {
    const headers = getAuthHeader();
    const { designId, printId, payload } = patchRequest;
    const response = await fetch(
      `${apiUrl}/designs/userdesigns/${designId}/print/${printId}/`,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return await response.json();
  }
);

export const createUserDesignLabelThunk = createAsyncThunk(
  "user_design/label/create",
  async (patchRequest: any) => {
    const headers = getAuthHeader();
    const { designId, payload } = patchRequest;
    const response = await fetch(
      `${apiUrl}/designs/userdesigns/${designId}/label/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return await response.json();
  }
);

export const patchUserDesignLabelThunk = createAsyncThunk(
  "user_design/label/patch",
  async (patchRequest: any) => {
    const headers = getAuthHeader();
    const { designId, labelId, payload } = patchRequest;
    const response = await fetch(
      `${apiUrl}/designs/userdesigns/${designId}/label/${labelId}/`,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return await response.json();
  }
);

export const deleteUserDesignLabelThunk = createAsyncThunk(
  "user_design/label/delete",
  async (patchRequest: any) => {
    const headers = getAuthHeader();
    const { designId, labelId } = patchRequest;
    const response = await fetch(
      `${apiUrl}/designs/userdesigns/${designId}/label/${labelId}/`,
      {
        method: "DELETE",
        headers: headers,
        credentials: "include",
      }
    );
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    return null;
  }
);

export const userDesignSlice = createSlice({
  name: "userDesign",
  initialState,

  reducers: {
    resetUserDesign: (state) => (state = initialState),
    update: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDesignThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(getUserDesignThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getUserDesignThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });
    builder.addCase(setUserDesignThunk.pending, (_state) => {});
    builder.addCase(setUserDesignThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(setUserDesignThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });

    builder.addCase(patchUserDesignThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(patchUserDesignThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(patchUserDesignThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });

    builder.addCase(patchUserDesignPrintThunk.pending, (_state) => {});
    builder.addCase(patchUserDesignPrintThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(patchUserDesignPrintThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });

    builder.addCase(createUserDesignLabelThunk.pending, (_state) => {});
    builder.addCase(createUserDesignLabelThunk.fulfilled, (state, action) => {
      if (state.data) state.data.label = action.payload;
    });
    builder.addCase(createUserDesignLabelThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });

    builder.addCase(patchUserDesignLabelThunk.pending, (_state) => {});
    builder.addCase(patchUserDesignLabelThunk.fulfilled, (state, action) => {
      if (state.data) state.data.label = action.payload;
    });
    builder.addCase(patchUserDesignLabelThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });

    builder.addCase(deleteUserDesignLabelThunk.pending, (_state) => {});
    builder.addCase(deleteUserDesignLabelThunk.fulfilled, (state, action) => {
      if (state.data) state.data.label = null;
    });
    builder.addCase(deleteUserDesignLabelThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });
  },
});

export const { update, resetUserDesign } = userDesignSlice.actions;

export default userDesignSlice.reducer;
