import { Wrap } from "./styles";
import { ProductionRange as ProductionRangeBean } from "../../beans";
import ProductionRangeItem from "../ProductionRangeItem";
import { useTranslation } from "react-i18next";

type Props = {
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  productionTime?: string;
  productionRange: ProductionRangeBean[];
};

const ProductionRange = (props: Props) => {
  const { productionTime, productionRange, desktopOnly, mobileOnly } = props;
  const { t } = useTranslation();

  return (
    <Wrap $mobileOnly={mobileOnly} $desktopOnly={desktopOnly}>
      {productionRange
        ? productionRange
            .slice(1)
            .map((unit, k) => (
              <ProductionRangeItem productionRangeItem={unit} key={k} />
            ))
        : null}
      {productionTime ? (
        <div className="production-detail">
          <div className="production-detail-unit">
            <div className="label-sm">
              {t("productionRange.productionTime")}
            </div>
            <div className="title-sm">{productionTime}</div>
          </div>
        </div>
      ) : null}
    </Wrap>
  );
};

export default ProductionRange;
