import {
  ArtworkDetail,
  ButtonEnum,
  Production,
  ProductionSize,
  ProductionTimeEnum,
  UserDesign,
} from "../../beans";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { Wrap } from "./styles";
import Button from "../Button";
import ArtworkPreview from "../ArtworkPreview";
import { useNavigate } from "react-router";
import { translateSize } from "../../utils/translate";
import Divider from "../Divider";
import { getPrintLocationThunk } from "../../store/PrintLocation";
import { useEffect } from "react";
import { goToEditDesign } from "../../constants/routes";
import { useTranslation } from "react-i18next";

type Props = {
  edit?: boolean;
  userDesign: UserDesign;
  production: Production;
};
const ProductionDesigns = (props: Props) => {
  const { userDesign, production, edit } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getPrintLocationThunk());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTotal = (data: any) => {
    let sum = 0;
    if (data) {
      Object.keys(data).forEach((key) => {
        sum += Number(data[key as keyof Object]);
      });

      return sum;
    }
  };

  return (
    <Wrap>
      <div className="production-designs-container">
        {userDesign && userDesign.print_set.length > 0 ? (
          userDesign.print_set.map((print: ArtworkDetail, k: number) => (
            <div className="print-preview" key={k}>
              <ArtworkPreview
                artwork={print}
                design={userDesign}
                size="small"
              />
            </div>
          ))
        ) : (
          <div className="print-preview" key="item-preview">
            <ArtworkPreview design={userDesign} size="small" />
          </div>
        )}
        <div className="production-designs-actions">
          <div className="title title-sm">{userDesign?.name}</div>
          <div className="actions">
            {edit && (
              <Button
                style={ButtonEnum.link}
                onClick={() => navigate(goToEditDesign(userDesign.id))}
              >
                {t("button.edit")}
              </Button>
            )}
            {!edit && production?.sizes && (
              <div className="sizes-container">
                {Object.keys(production.sizes).map(function (
                  key: any,
                  i: number
                ) {
                  return (
                    production &&
                    production.sizes &&
                    // @ts-ignore
                    production.sizes[key as keyof ProductionSize] > 0 && (
                      <div className="size-row" key={`${key}_${i}`}>
                        <div className="label-sm size">
                          {translateSize(key)}
                        </div>
                        <div className="body-sm">
                          {production.sizes[key as keyof ProductionSize]}
                        </div>
                      </div>
                    )
                  );
                })}
                <Divider vertical />

                <div className="size-row" key="total">
                  <div className="label-sm size">
                    {" "}
                    {t("productionSummary.totalUnits")}
                  </div>
                  <div className="body-sm total">
                    {getTotal(production.sizes)}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="sizes-container">
            {t("productionSummary.productionTime")}:{" "}
            {!!production.sample
              ? ProductionTimeEnum.sample
              : ProductionTimeEnum.production}{" "}
            {t("vocabulary.weeks")}
          </div>
        </div>
      </div>
    </Wrap>
  );
};

export default ProductionDesigns;
