import { Wrap } from "./styles";
import { priceFormat } from "../../utils/format";
import { ProductionRange as ProductionRangeBean } from "../../beans";
import { useTranslation } from "react-i18next";

type Props = {
  productionTime?: string;
  productionRangeItem: ProductionRangeBean;
};

const ProductionRangeItem = (props: Props) => {
  const { productionTime, productionRangeItem } = props;
  const { t } = useTranslation();

  return (
    <Wrap>
      {productionTime ? (
        <div className="production-detail-unit">
          <div className="label-sm">{t("productionRange.productionTime")}</div>
          <div className="title-sm">{productionTime}</div>
        </div>
      ) : (
        <div className="production-detail-unit">
          <div className="label-sm">
            {productionRangeItem?.production_range}
          </div>
          <div className="title-sm">
            {priceFormat(productionRangeItem?.unit_total?.toString())}{" "}
            {t("orderProduction.euroUnit")}
          </div>
        </div>
      )}
    </Wrap>
  );
};

export default ProductionRangeItem;
