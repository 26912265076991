import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PrintType, PrintTypeEnum } from "../../beans";

export interface PrintTypeState {
  data: PrintType[];
  label: PrintType[];
  error: null | any;
  selected: null | PrintType;
  selectedLabel: null | PrintType;
}

const initialState: PrintTypeState = {
  data: [
    {
      id: "1",
      type: PrintTypeEnum.screen_print,
      label: "printType.label.screen_print",
      description: "printType.description.upTo4colors",
    },
    {
      id: "2",
      type: PrintTypeEnum.embroidery,
      label: "printType.label.embroidery",
      description: "printType.description.upTo4colors",
    },
    {
      id: "3",
      type: PrintTypeEnum.dtf,
      label: "printType.label.dtf",
      description: "printType.description.unlimitedColors",
    },
  ],
  label: [
    {
      id: "4",
      type: PrintTypeEnum.label,
      label: "printType.label.label",
      description: "printType.description.labelSize",
    },
    {
      id: "0",
      type: PrintTypeEnum.label,
      label: "printType.label.noLabel",
      description: "",
    },
  ],
  error: null,
  selected: {
    id: "3",
    type: PrintTypeEnum.dtf,
    label: "printType.label.dtf",
    description: "printType.description.unlimitedColors",
  },
  selectedLabel: {
    id: "4",
    type: PrintTypeEnum.label,
    label: "printType.label.label",
    description: "printType.description.labelSize",
  },
};

// TODO : miss endpoint for PrintType - let's go static!
export const getPrintTypeThunk = createAsyncThunk(
  "print-type/request",
  async () => {
    return initialState;
  }
);

export const printTypeSlice = createSlice({
  name: "printType",
  initialState,
  reducers: {
    resetPrintType: (state) => {
      state = initialState;
    },
    selectPrintType: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    selectLabelPrintType: (state, action: PayloadAction<any>) => {
      state.selectedLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrintTypeThunk.pending, (state) => {
      state.data = [];
    });
    builder.addCase(getPrintTypeThunk.fulfilled, (state, action) => {
      state.data = action.payload.data;
      // state.selected = action.payload.data[0];
    });
    builder.addCase(getPrintTypeThunk.rejected, (state, action) => {
      state.error = action.error.message!;
    });
  },
});

export const { selectPrintType, resetPrintType, selectLabelPrintType } =
  printTypeSlice.actions;

export default printTypeSlice.reducer;
