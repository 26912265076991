import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProductionRangeThunk } from "../../store/ProductionRange";
import { AppDispatch, RootState } from "../../store";
import Header from "../../components/Header";
import NavigationBar from "../../components/NavigationBar";
import PaddingManager from "../../components/PaddingManager";
import ProductionRange from "../../components/ProductionRange";
import RenderCanva from "../../components/RenderCanva";
import TabMenu from "../../components/TabMenu";
import { Wrap } from "./styles";
import { getUserDesignThunk } from "../../store/UserDesign";
import { getItemRenderListThunk } from "../../store/ItemRenderList";
import { fetchFabricRequest } from "../../store/Fabric";
import { ProductionTimeEnum } from "../../beans";
import RenderLabel from "../../components/RenderLabel";
import { usePreviousValue } from "../../hooks/usePreviousValue";
import { useTranslation } from "react-i18next";

const DesignNew = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { designId } = useParams();

  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const canvas = useSelector((state: RootState) => state.itemsRenderList.data);
  const tab = useSelector((state: RootState) => state.ui.tab);
  const previousTab = usePreviousValue(tab.selected);

  const selectedItem = useSelector(
    (state: RootState) => state.userDesign?.data?.item_fabric?.item
  );

  const productionRange = useSelector(
    (state: RootState) => state.productionRange.data
  );

  // HOOKS

  useEffect(() => {
    if (designId) {
      dispatch(getUserDesignThunk(designId));
      dispatch(getProductionRangeThunk(Number(designId)));
      dispatch(fetchFabricRequest(designId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDesign?.item_fabric) {
      dispatch(
        getItemRenderListThunk({
          itemId: userDesign.item_fabric?.item?.id,
          colorId: userDesign.item_fabric?.id,
          label: tab.selected === "label",
        })
      );
    }
  }, [userDesign?.item_fabric?.item?.id, userDesign?.item_fabric?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (previousTab === "label" || tab.selected === "label") {
      if (userDesign?.item_fabric) {
        dispatch(
          getItemRenderListThunk({
            itemId: userDesign.item_fabric?.item?.id,
            colorId: userDesign.item_fabric?.id,
            label: tab.selected === "label",
          })
        );
      }
    }
  }, [tab.selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return designId ? (
    <>
      <Header
        product
        productName={selectedItem?.name}
        productType={userDesign?.name}
      />
      <Wrap>
        <div className="design-container">
          <div className="design-preview">
            <PaddingManager>
              {tab.selected === "label" ? (
                <RenderLabel canvas={canvas} />
              ) : (
                <RenderCanva canvas={canvas} />
              )}
              {canvas && productionRange && (
                <ProductionRange
                  desktopOnly
                  productionTime={`${ProductionTimeEnum.production}  ${t("vocabulary.weeks")}`}
                  productionRange={productionRange}
                />
              )}
            </PaddingManager>
          </div>
          <div className="design-options">
            <TabMenu sticky />
            <NavigationBar />
          </div>
        </div>
      </Wrap>
    </>
  ) : null;
};

export default DesignNew;
