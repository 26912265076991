import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { Production } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { getOrderSummaryRequest } from "../Order";
import { showErrorModal } from "../UI";
import { getUserDesign } from "../UserDesign/selectors";
import { initialProduction_100 } from "../../constants/productions";
export interface ProductionState {
  data: Production | null;
  productionTime: string;
  error: null | any;
  loading: boolean;
  loaded: boolean;
}

const initialState: ProductionState = {
  data: null,
  productionTime: "2 - 6",
  error: null,
  loading: false,
  loaded: false,
};

// DEFINE SAGAS

// CREATE PRODUCTION
function* createProductionSaga(): Generator<StrictEffect, void, any> {
  const userDesign = yield select(getUserDesign());

  if (userDesign) {
    try {
      // TODO remove this mock, find a better solution to get init Production
      const initialProduction = initialProduction_100;
      const headers = getAuthHeader();
      const response: any = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${userDesign.id}/production/`,
        {
          method: "POST",
          body: JSON.stringify(initialProduction),
          headers: headers,
          credentials: "include",
        }
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(createProductionSuccess(data));
      } else {
        yield put(createProductionFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: any) {
      yield put(createProductionFailure(error));
      yield put(showErrorModal(error));
    }
  }
}

// GET ALL PRODUCTIONS
function* getAllProductionSaga(): Generator<StrictEffect, void, any> {
  const userDesign = yield select(getUserDesign());

  if (userDesign) {
    const headers = getAuthHeader();

    try {
      const response: any = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${userDesign.id}/production/`,
        {
          headers: headers,
          credentials: "include",
        }
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(getAllProductionSuccess(data));
      } else {
        yield put(getAllProductionFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: any) {
      console.error("ERROR", error);
      yield put(showErrorModal(error));
    }
  }
}

// GET PRODUCTION BY ID
function* getProductionByIdSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  const { designId, productionId } = action.payload;

  if (designId && productionId) {
    const headers = getAuthHeader();
    try {
      const response: any = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${designId}/production/${productionId}`,
        {
          headers: headers,
          credentials: "include",
        }
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(getProductionByIdSuccess(data));
      } else {
        yield put(getProductionByIdFailure(data));
        yield put(showErrorModal(getErrors(data)));
      }
    } catch (error: any) {
      console.error("ERROR", error);
      yield put(getProductionByIdFailure(error));
      yield put(showErrorModal(error));
    }
  }
}

function* setProductionSaga(
  action: PayloadAction<any>
): Generator<StrictEffect, void, any> {
  const userDesign = yield select(getUserDesign());
  const { productionId, orderId, body } = action.payload;

  if (userDesign?.id) {
    try {
      const headers = getAuthHeader();
      const response: any = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${userDesign.id}/production/${productionId}/`,
        {
          method: "PUT",
          body: JSON.stringify(body),
          headers: headers,
          credentials: "include",
        }
      );

      const data = yield response.json();

      if (response.ok) {
        yield put(setProductionSuccess(data));
        yield put(getOrderSummaryRequest(orderId));
      } else {
        yield put(setProductionFailure(data));
      }
    } catch (error: any) {
      console.error("ERROR PRODUCTION", error);
      const errorMessage =
        "The server encountered an unexpected problem. Try again";
      yield put(setProductionFailure({ serverError: errorMessage }));
      yield put(showErrorModal(errorMessage));
    }
  }
}

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    resetProduction: (state) => (state = initialState),
    createProductionRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    createProductionSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    createProductionFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    getAllProductionRequest: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
      state.loading = true;
    },
    getAllProductionSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getAllProductionFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    getProductionByIdRequest: (state, _action) => {
      state.data = initialState.data;
      state.error = initialState.error;
      state.loading = true;
    },
    getProductionByIdSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getProductionByIdFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    setProductionRequest: (state, action: PayloadAction<any>) => {
      state.error = initialState.error;
      state.loading = true;
    },
    setProductionSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    setProductionFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
  },
});

// TODO define actions better in bindActionCreators.tsx
export const {
  resetProduction,
  createProductionRequest,
  createProductionSuccess,
  createProductionFailure,
  getAllProductionRequest,
  getAllProductionSuccess,
  getAllProductionFailure,
  getProductionByIdRequest,
  getProductionByIdSuccess,
  getProductionByIdFailure,
  setProductionRequest,
  setProductionSuccess,
  setProductionFailure,
} = productionSlice.actions;

export default productionSlice.reducer;

export function* sagas() {
  yield takeEvery(createProductionRequest.type, createProductionSaga);
  yield takeEvery(getAllProductionRequest.type, getAllProductionSaga);
  yield takeEvery(getProductionByIdRequest.type, getProductionByIdSaga);
  yield takeEvery(setProductionRequest.type, setProductionSaga);
}
