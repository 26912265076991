import { MouseEventHandler } from "react";
import { showInfoBox } from "../../store/UI";
import { AppDispatch } from "../../store";

import { ActionCardEnum, ButtonEnum } from "../../beans";
import { info as infoIcon } from "../../constants/icons";

import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { useDispatch } from "react-redux";

type Props = {
  type: ActionCardEnum;
  title?: string;
  label?: string;
  price?: string;
  selected?: boolean;
  disabled?: boolean;
  info?: boolean;
  code?: string;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const ActionCard = (props: Props) => {
  const { type, title, label, price, selected, disabled, info, code } = props;
  const dispatch = useDispatch<AppDispatch>();

  const renderSimple = () => {
    return (
      <div
        className="card-content"
        onClick={(e) => (!disabled && props.onClick ? props.onClick(e) : null)}
      >
        <div className="card-info">
          <div className="body-sm title">{title}</div>
          <div className="label-sm label">{label}</div>
        </div>
      </div>
    );
  };
  const renderExpanded = () => {
    return (
      <div
        className="card-content"
        onClick={(e) => (!disabled && props.onClick ? props.onClick(e) : null)}
      >
        <div className="card-info">
          <div className="body-sm title">{title}</div>
          <div className="label-sm label">{label}</div>
        </div>
        <div className="label-lg price">{price}</div>
      </div>
    );
  };
  return (
    <Wrap
      className={`action-card ${type}`}
      $selected={selected}
      $disabled={disabled}
    >
      {type === ActionCardEnum.expanded ? renderExpanded() : renderSimple()}
      {info && (
        <div className="action-info-button">
          <Button
            style={ButtonEnum.icon}
            onClick={() =>
              dispatch(
                showInfoBox({
                  open: true,
                  key: code,
                })
              )
            }
          >
            <Icon info icon={infoIcon}></Icon>
          </Button>
        </div>
      )}
    </Wrap>
  );
};

export default ActionCard;
