import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ItemRender } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface ItemRenderListState {
  data: ItemRender[] | null;
  error: null | any;
  selected: ItemRender | null;
  loaded: boolean;
  loading: boolean;
}

const initialState: ItemRenderListState = {
  data: [],
  error: null,
  selected: null,
  loaded: false,
  loading: false,
};

export const getItemRenderListThunk = createAsyncThunk(
  "itemrender_list/request",
  async (payload: any) => {
    if (payload.itemId && payload.colorId) {
      const response = await fetch(
        `${apiUrl}/designs/itemrender/?item_id=${payload.itemId}&itemfabric_id=${payload.colorId}&label=${payload.label}`,
        {
          headers: { accept: "application/json" },
        }
      );
      return await response.json();
    }
  }
);

export const itemRenderListSlice = createSlice({
  name: "itemRenders",
  initialState,
  reducers: {
    selectRenderItemList: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItemRenderListThunk.pending, (state, action) => {
      // state.data = [];
      state.loaded = false;
      state.loading = true;
    });
    builder.addCase(getItemRenderListThunk.fulfilled, (state, action) => {
      // TODO : code it better! SAGA!
      let selected = null;
      state.data = action.payload;

      if (state.selected) {
        selected = state.data?.find((item) => item.id === state.selected?.id);
        state.selected = selected ?? action.payload[0];
      } else {
        state.selected = action.payload[0];
      }

      state.loaded = true;
      state.loading = false;
    });
    builder.addCase(getItemRenderListThunk.rejected, (state, action) => {
      state.error = action.error.message!;
      state.loaded = true;
      state.loading = false;
    });
  },
});

export const { selectRenderItemList } = itemRenderListSlice.actions;

export default itemRenderListSlice.reducer;
